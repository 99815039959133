<template>
  <div>
    <div v-if="!loading" class="row">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
<!--              <img src="/assets/images/avatars/avatar-1.png" alt="Admin" class="rounded-circle p-1 bg-primary" width="110">-->
              <div style="width: 100%">
                <div v-if="edit">
                  <div class="row mb-3">
                    <div class="col-sm-3">
                      <h6 class="mb-0" style="margin-top: 8px;">First Name</h6>
                    </div>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" v-model="user.fname">
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-3">
                      <h6 class="mb-0" style="margin-top: 8px;">Last Name</h6>
                    </div>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" v-model="user.lname">
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-3">
                      <h6 class="mb-0" style="margin-top: 8px;">Email</h6>
                    </div>
                    <div class="col-sm-9">
                      <input type="email" class="form-control" v-model="user.email">
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-3">
                      <h6 class="mb-0" style="margin-top: 8px;">Job Title</h6>
                    </div>
                    <div class="col-sm-9">
                      <input type="email" class="form-control" v-model="user.job_title">
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-sm-3">
                      <h6 class="mb-0" style="margin-top: 8px;">User Type</h6>
                    </div>
                    <div class="col-sm-9">
                      <select-2 id="userType" v-model="user.user_type" :value="user.user_type" class="single-select" :options="types">
                        <option selected disabled>Select a Type</option>
                      </select-2>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-9">
                      <button class="btn btn-primary" @click="edit = !edit">Back</button>
                      <submit-button :background="'btn btn-light mx-2'" :clicked="updating" :text="'Save Changes'" @click="updateUser"></submit-button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h4>{{user.fname}} {{user.lname}}</h4>
                  <p class="mb-2">{{user.email}}</p>
                  <button class="btn btn-primary" @click="edit = !edit">Edit</button>
                  <button class="btn btn-light ms-2" @click="resetPassword">Reset Password</button>
                  <button v-if="user.status === 'Active'" @click="disableUser" class="btn btn-danger mx-2">Disable User</button>
                  <button v-else class="btn btn-success mx-2" @click="enableUser">Enable User</button>

                  <button class="btn btn-primary" @click="$router.push(`/p/users/${user.id}/jobs`)">View Jobs</button>
                </div>
              </div>
            </div>
<!--            <hr class="my-4">-->
<!--            <ul class="list-group list-group-flush">-->
<!--              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">-->
<!--                <h6 class="mb-0">Job Role</h6>-->
<!--                <span class="text-white">Software Developer</span>-->
<!--              </li>-->
<!--              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">-->
<!--                <h6 class="mb-0">Pay Type</h6>-->
<!--                <span class="text-white">Salary</span>-->
<!--              </li>-->
<!--              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">-->
<!--                <h6 class="mb-0">National Insurance Number</h6>-->
<!--                <span class="text-white">QQ 123456 C</span>-->
<!--              </li>-->
<!--              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">-->
<!--                <h6 class="mb-0">Tax Band</h6>-->
<!--                <span class="text-white">1257L</span>-->
<!--              </li>-->
<!--              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">-->
<!--                <h6 class="mb-0">User Type</h6>-->
<!--                <span class="text-white">{{user.type.name}}</span>-->
<!--              </li>-->
<!--              <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">-->
<!--                <h6 class="mb-0">Roles</h6>-->
<!--                <span class="text-white">Admin, Support, Sales</span>-->
<!--              </li>-->
<!--            </ul>-->
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <h5 class="card-title">Vehicles <button data-bs-toggle="modal" data-bs-target="#createVehicleModal" class="btn btn-success btn-sm float-end">Add</button></h5>
            <table id="vehiclesTable" class="table table-hover" style="width:100%">
              <thead>
              <tr>
                <th>#</th>
                <th>VRM</th>
                <th>Type</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="vehicle in user.vehicles" :key="'uservehicle-' + vehicle.id">
                  <td>{{vehicle.id}}</td>
                  <td>{{vehicle.registration}}</td>
                  <td>{{vehicle.type}}</td>
                  <td><button class="btn btn-sm btn-light" @click="currentVehicle = vehicle" data-bs-toggle="modal" data-bs-target="#updateVehicleModal">Edit</button><button class="btn btn-sm btn-danger ms-2">Remove</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body">
            <h5 class="card-title">Devices <button @click="showStaging" class="btn btn-success btn-sm float-end">Add</button></h5>
            <table id="devicesTable" class="table table-hover" style="width:100%">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th></th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="device in user.devices" :key="'userdevice-' + device.id">
                  <td>{{device.id}}</td>
                  <td>{{device.name}}</td>
                  <td><button class="btn btn-sm btn-danger" @click="removeDevice(device)">Remove</button></td>
                  <td><button class="btn btn-sm btn-primary" @click="manageDevice(device)">Manage Modules</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- USER TRAINING -->
        <div class="card mt-2">
          <div class="card-body">
            <h5 class="card-title">Training <button @click="showTraining" class="btn btn-success btn-sm float-end">Add</button></h5>
            <table id="devicesTable" class="table table-hover" style="width:100%">
              <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Start</th>
                <th>Expiry</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="training in trainingModules" :key="'usertraining-' + training.id">
                  <td>{{training.id}}</td>
                  <td>{{training.name}}</td>
                  <td>{{training.start}}</td>
                  <td>{{training.expiry}}</td>
                  <td>
                    <button class="btn btn-sm btn-secondary" style="margin-right: 10px;" @click="editTraining(training)">Edit</button>
                    <button class="btn btn-sm btn-danger" @click="removeTraining(training)">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- USER OVERNIGHTS -->
        <div class="card mt-2">
          <div class="card-body">
            <h5 class="card-title">Overnights<h6 class="float-end">Total: {{ totalOvernights }}</h6></h5>
            <div class="row mt-2">
              <div class="col">
                <div class="form-group">
                  <label for="monthFilterOvernight">Month</label>
                  <input type="month" class="form-control" v-model="overnightsFilter.month" id="monthFilterOvernight">
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <button class="btn btn-primary btn-sm float-end" @click="loadOvernights">Search</button>
              </div>
            </div>

            <table id="overnights" class="table table-hover" style="width: 100%;">
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Overnights</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="o in overnights" :key="'userovernights-' + o.week">
                  <td>{{o.week}}</td>
                  <td>{{o.overnights}}</td>
                  <td>
                    <button class="btn btn-sm btn-danger" @click="deleteOvernight(o)">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- USER ANNUAL LEAVE
        <div class="card mt-2">
          <div class="card-body">
            <h5 class="card-title">Annual Leave <button @click="showAnnualLeave" class="btn btn-success btn-sm float-end">Add</button></h5>
            <div class="row mt-2">
              <div class="col">
                <div class="form-group">
                  <label for="startLeave">Start</label>
                  <input type="date" class="form-control" v-model="annualLeaveFilters.start" id="startLeave">
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="endLeave">End</label>
                  <input type="date" class="form-control" v-model="annualLeaveFilters.end" id="endLeave">
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <button class="btn btn-primary btn-sm float-end" @click="loadAnnualLeave">Search</button>
              </div>
            </div>

            <table id="annualLeave" class="table table-hover" style="width: 100%;">
              <thead>
                <tr>
                  <th>Start</th>
                  <th>End</th>
                  <th>Notes</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="l in leave" :key="'userleave-' + l.id">
                  <td>{{l.start_date}}</td>
                  <td>{{l.end_date}}</td>
                  <td>{{l.notes}}</td>
                  <td>
                    <button class="btn btn-sm btn-danger" @click="deleteAnnualLeave(l)">Delete</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
      </div>
      <div class="col-lg-8">
        <!-- Roles -->
        <div v-if="edit" class="row">
          <div class="card">
            <div class="card-body">

              <h5 class="card-title">User Roles</h5>

              <pagination @search="loadRoles" :data="rolePagination"></pagination>

              <table id="example" class="table table-hover" style="width:100%">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="role in roles" :key="role.id + '-role'" style="cursor: pointer;">
                  <td>{{role.id}}</td>
                  <td>{{role.name}}</td>
                  <td>
                    <button class="btn btn-sm btn-danger" @click="removeRole(role)" v-if="role.assigned">Remove Role</button>
                    <button class="btn btn-sm btn-success" @click="assignRole(role)" v-else>Add Role</button>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                </tfoot>
              </table>

              <pagination @search="loadRoles" :data="rolePagination"></pagination>

            </div>
          </div>
        </div>

        <!-- Clients -->
        <div v-if="edit" class="row">
          <div class="card">
            <div class="card-body">

              <h5 class="card-title">User Clients</h5>

              <div class="position-relative wd-50"><input type="text" v-model="clientSearch" v-on:keyup="loadClients(clientPagination.page)" class="form-control ps-5" placeholder="Search Site..."> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span></div>

              <pagination @search="loadClients" :data="clientPagination"></pagination>

              <table id="example" class="table table-hover" style="width:100%">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="client in clients" :key="client.id + '-client'" style="cursor: pointer;">
                  <td>{{client.id}}</td>
                  <td>{{client.name}}</td>
                  <td>
                    <button class="btn btn-sm btn-danger" @click="removeClient(client)" v-if="client.assigned">Remove Client</button>
                    <button class="btn btn-sm btn-success" @click="assignClient(client)" v-else>Add Client</button>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th></th>
                </tr>
                </tfoot>
              </table>

              <pagination @search="loadClients" :data="clientPagination"></pagination>

            </div>
          </div>
        </div>

        <!-- Bulk Assign Jobs -->
        <div class="row">
          <div class="card">
            <div class="card-body">

              <h5 class="card-title">Bulk Assign Jobs</h5>
              <p>To assign jobs enter the job numbers separated by commas. For example: 1000,1001,1002,1003</p>

              <div class="form-group">
                <label for="jobNumbers">Job Numbers</label>
                <input type="text" class="form-control" v-model="jobNumbers" id="jobNumbers">
              </div>

              <button class="btn btn-primary" @click="assignJobs">Assign Jobs</button>

            </div>
          </div>
        </div>

        <!-- Paysheet -->
        <div class="row">
          <div class="card">
            <div class="card-body">

              <p>
                Generate paysheet for period
                <input @change="loadPaysheet" style="max-width: 200px;" class="form-control form-control-sm d-inline-block" v-model="paysheet.start" type="date">
                to
                <input @change="loadPaysheet" style="max-width: 200px;" v-model="paysheet.end" class="form-control form-control-sm d-inline-block" type="date">
                <submit-button background="btn btn-sm btn-primary ms-2" :clicked="loadingPaysheet" @click="loadPaysheet" text="Generate"/>
              </p>

              <div class="mt-2" v-if="paysheet.timesheets.payable">
                <p><strong>Total Clock In:</strong> <view-duration :seconds="paysheet.timesheets.payable.total"></view-duration></p>
                <p><strong>Deductions:</strong> <view-duration :seconds="paysheet.timesheets.payable.deducted"></view-duration></p>
                <p><strong>Total Payable:</strong> <view-duration :seconds="paysheet.timesheets.payable.result"></view-duration></p>
              </div>

              <div class="mt-2" v-if="paysheet.mileage.billable">
                <p><strong>Total Mileage:</strong> {{parseFloat(paysheet.mileage.billable.total).toFixed(2)}} Miles</p>
                <p><strong>Total Private Mileage:</strong> {{parseFloat(paysheet.mileage.billable.private).toFixed(2)}} Miles</p>
                <p><strong>Deductions:</strong> {{parseFloat(paysheet.mileage.billable.deducted).toFixed(2)}} Miles</p>
                <p><strong>Billable Private Mileage:</strong> {{parseFloat(paysheet.mileage.billable.result).toFixed(2)}} Miles</p>
              </div>

            </div>
          </div>
        </div>

        <!-- Timesheets -->
        <div class="row">
          <div class="card">
            <div class="card-body">

              <p>
                Showing entries for
                <input @change="loadTimesheets" style="max-width: 200px;" class="form-control form-control-sm d-inline-block" v-model="timesheets.start" type="date">
                to
                <input @change="loadTimesheets" style="max-width: 200px;" v-model="timesheets.end" class="form-control form-control-sm d-inline-block" type="date">
                <button class="btn btn-sm btn-primary float-end" data-bs-toggle="modal" data-bs-target="#createEntryModal">Add Timesheet</button>
              </p>

              <table id="timesheet-entries" class="table table-hover" style="width:100%">
                <thead>
                  <tr>
                    <th>Start</th>
                    <th>End</th>
                    <th>Deductions</th>
                    <th>Mileage Deductions</th>
                    <th>Start Mileage</th>
                    <th>End Mileage</th>
                    <th>Driver</th>
                    <th>Duration</th>
                    <th>Status</th>
                    <th>Vehicle</th>
                    <th>Edited</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="entry in timesheets.entries" :key="'entry-' + entry.id">
                    <td><Time :datetime="entry.start" format="dd/MM/yyyy HH:mm:ss" /></td>
                    <td><Time :datetime="entry.end" format="dd/MM/yyyy HH:mm:ss" /></td>
                    <td>{{entry.deductions || 0}} Minutes</td>
                    <td>{{entry.mileage_deductions || 0}} Miles</td>
                    <td>
                      {{ entry.start_mileage?.toLocaleString() || 'N/A' }}
                      <span v-show="entry.diff_since_last">(+{{ (entry.diff_since_last).toLocaleString() }})</span>
                    </td>
                    <td>
                      {{ entry.end_mileage?.toLocaleString() || 'N/A' }}
                    </td>
                    <td>{{entry.driver_name || "N/A"}}</td>
                    <td>{{entry.duration}}</td>
                    <td>
                      <span v-if="entry.verified == 1" class="badge rounded-pill bg-success">Verified</span>
                      <span v-else class="badge rounded-pill bg-warning text-dark">Unverified</span>
                    </td>
                    <td>{{entry.registration || "N/A"}}</td>
                    <td>{{ entry.edited === 1 ? 'YES' : "NO" }}</td>
                    <td>
                      <button type="button" class="btn btn-sm btn-light" @click="timesheets.edit = entry;" data-bs-toggle="modal" data-bs-target="#editEntryModal"><i class="bx bx-edit-alt me-0"></i></button>
                      <button type="button" class="btn btn-sm ms-2 btn-danger" @click="deleteTimesheet(entry)"><i class="bx bx-trash me-0"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- MODALS -->
    <div class="modal fade" id="createEntryModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Timesheet Entry</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-2">
              <label class="form-label">Deductions</label>
              <input class="form-control" type="number" v-model="timesheets.new.deductions" placeholder="Deductions">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Mileage Deductions</label>
              <input class="form-control" type="number" step="0.01" v-model="timesheets.new.mileage_deductions" placeholder="Deductions">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Start</label>
              <input type="datetime-local" v-model="timesheets.new.start" class="form-control">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">End</label>
              <input type="datetime-local" v-model="timesheets.new.end" class="form-control">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Start Mileage</label>
              <input class="form-control" type="number" v-model="timesheets.new.start_mileage" placeholder="Start Mileage">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">End Mileage</label>
              <input class="form-control" type="number" v-model="timesheets.new.end_mileage" placeholder="End Mileage">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Driver</label>
              <input class="form-control" type="string" v-model="timesheets.new.driver_name" placeholder="Driver">
            </div>
            <div class="form-check">
              <input class="form-check-input" v-model="timesheets.new.verified" type="checkbox" value="" id="flexCheckNewEntry">
              <label class="form-check-label" for="flexCheckNewEntry">Verified</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateEntryModal'>Close</button>
            <submit-button type="button" :clicked="timesheets.creating" :background="'btn btn-primary'" @click="createTimesheet" data-bs-dismiss="modal" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editEntryModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage Timesheet Entry</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-2">
              <label class="form-label">Deductions</label>
              <input class="form-control" type="number" v-model="timesheets.edit.deductions" placeholder="Deductions">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Mileage Deductions</label>
              <input class="form-control" type="number" step="0.01" v-model="timesheets.edit.mileage_deductions" placeholder="Deductions">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Start</label>
              <input type="datetime-local" v-model="timesheets.edit.formattedStart" class="form-control">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">End</label>
              <input type="datetime-local" v-model="timesheets.edit.formattedEndNoDeductions" class="form-control">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Start Mileage</label>
              <input class="form-control" type="number" v-model="timesheets.edit.start_mileage" placeholder="Start Mileage">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">End Mileage</label>
              <input class="form-control" type="number" v-model="timesheets.edit.end_mileage" placeholder="End Mileage">
            </div>
            <div class="form-group mb-2">
              <label class="form-label">Driver</label>
              <input class="form-control" type="string" v-model="timesheets.edit.driver_name" placeholder="Driver">
            </div>
            <div class="form-check">
              <input class="form-check-input" v-model="timesheets.edit.formattedVerification" type="checkbox" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">Verified</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeEditDeductionsModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" @click="updateTimesheet" data-bs-dismiss="modal" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="createVehicleModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Vehicle</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleRegistration" class="form-label">VRM <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="vehicleRegistration" v-model="vehicle.registration">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleVin" class="form-label">VIN Number</label>
                <input type="text" class="form-control" id="vehicleVin" v-model="vehicle.vin">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleMake" class="form-label">Make <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="vehicleMake" v-model="vehicle.make">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleModel" class="form-label">Model <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="vehicleModel" v-model="vehicle.model">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleColour" class="form-label">Colour <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="vehicleColour" v-model="vehicle.colour">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleType" class="form-label">Type <span class="text-danger">*</span></label>
                <select v-model="vehicle.type" id="vehicleType" :value="vehicle.type" class="form-control bg-dark">
                  <option value="Engineer">Engineer</option>
                  <option value="Office">Office</option>
                  <option value="Personal">Personal</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateVehicleModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createVehicle" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="updateVehicleModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Vehicle</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleRegistrationUpdate" class="form-label">VRM</label>
                <input type="text" class="form-control" id="vehicleRegistrationUpdate" v-model="currentVehicle.registration">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleVinUpdate" class="form-label">VIN Number</label>
                <input type="text" class="form-control" id="vehicleVinUpdate" v-model="currentVehicle.vin">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleMakeUpdate" class="form-label">Make</label>
                <input type="text" class="form-control" id="vehicleMakeUpdate" v-model="currentVehicle.make">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleModelUpdate" class="form-label">Model</label>
                <input type="text" class="form-control" id="vehicleModelUpdate" v-model="currentVehicle.model">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleColourUpdate" class="form-label">Colour</label>
                <input type="text" class="form-control" id="vehicleColourUpdate" v-model="currentVehicle.colour">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleTypeUpdate" class="form-label">Type <span class="text-danger">*</span></label>
                <select v-model="currentVehicle.type" id="vehicleTypeUpdate" :value="currentVehicle.type" class="form-control bg-dark">
                  <option value="Engineer">Engineer</option>
                  <option value="Office">Office</option>
                  <option value="Personal">Personal</option>
                </select>
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleTrackerImeiUpdate" class="form-label">Tracker IMEI</label>
                <input type="text" class="form-control" id="vehicleTrackerImeiUpdate" v-model="currentVehicle.tracker_imei">
              </div>
              <div class="col-md-12 mb-2">
                <div v-if="currentVehicle.type === 'Personal'" class="form-check">
                  <input class="form-check-input" v-model="currentVehicle.track_mileage" type="checkbox" value="" id="flexCheckTrackMileage">
                  <label class="form-check-label" for="flexCheckTrackMileage">Track Mileage?</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeUpdateVehicleModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="updateVehicle" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="viewStagingModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Stage Device</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">

                <table id="staging-list" class="table table-hover" style="width:100%">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Key</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Created At</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody v-if="!staging.loading">
                    <tr v-if="staging.devices.length < 1">
                      <td colspan="5" class="text-center">No devices found.</td>
                    </tr>
                    <tr v-for="device in staging.devices" :key="device.id">
                      <td>{{device.id}}</td>
                      <td>{{device.staging_key}}</td>
                      <td><input type="text" class="form-control form-control-sm" v-model="device.name"></td>
                      <td>{{device.type}}</td>
                      <td><Time :datetime="device.created_at" format="dd/MM/yyyy HH:mm:ss"></Time></td>
                      <td>
                        <button class="btn btn-sm btn-success" @click="assignDevice(device)">Assign to User</button>
                        <button class="btn btn-sm btn-danger ms-2" @click="deleteDevice(device)">Delete Device</button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" class="text-center">
                        <div class="spinner-border" role="status"> <span class="visually-hidden">Loading...</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="viewManageDeviceModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage Device</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div v-if="currentDevice" class="col-12">

                <div v-for="module in availableModules" :key="'module-' + module" class="form-check form-switch">
                  <input class="form-check-input" v-model="currentDevice.modules[module]" type="checkbox" id="flexSwitchCheckDefault">
                  <label class="form-check-label" for="flexSwitchCheckDefault">{{module}}</label>
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeManageDeviceModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="updateDeviceModules" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="addTrainingModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Training</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">

                <div class="form-group mb-2">
                  <label class="form-label">Training Name <span class="text-danger">*</span></label>
                  <input class="form-control" type="text" v-model="training.name" placeholder="Training Name">
                </div>

                <div class="form-group mb-2">
                  <label class="form-label">Start <span class="text-danger">*</span></label>
                  <input type="date" v-model="training.start" class="form-control">
                </div> 

                <div class="form-group mb-2">
                  <label class="form-label">Expiry <span class="text-danger">*</span></label>
                  <input type="date" v-model="training.expiry" class="form-control">
                </div>

                <button class="btn btn-primary" @click="addTraining">Add Training</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="editTrainingModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Training</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">

                <div class="form-group mb-2">
                  <label class="form-label">Training Name <span class="text-danger">*</span></label>
                  <input class="form-control" type="text" v-model="training.name" placeholder="Training Name">
                </div>

                <div class="form-group mb-2">
                  <label class="form-label">Start <span class="text-danger">*</span></label>
                  <input type="date" v-model="training.start" class="form-control">
                </div> 

                <div class="form-group mb-2">
                  <label class="form-label">Expiry <span class="text-danger">*</span></label>
                  <input type="date" v-model="training.expiry" class="form-control">
                </div>

                <button class="btn btn-primary" @click="updateTraining">Save Changes</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Annual Leave Modal
    <div class="modal fade" id="addAnnualLeaveModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Annual Leave</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">

                <div class="form-group mb-2">
                  <label class="form-label">Start <span class="text-danger">*</span></label>
                  <input type="date" v-model="annualLeave.start" class="form-control">
                </div>

                <div class="form-group mb-2">
                  <label class="form-label">End <span class="text-danger">*</span></label>
                  <input type="date" v-model="annualLeave.end" class="form-control">
                </div>

                <div class="form-group mb-2">
                  <label class="form-label">Notes <span class="text-danger">*</span></label>
                  <textarea class="form-control" v-model="annualLeave.notes" placeholder="Notes"></textarea>
                </div>

                <button class="btn btn-primary" @click="postAnnualLeave">Add Annual Leave</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import SubmitButton from '../../../../components/SubmitButton.vue';
import {DateTime, Duration} from 'luxon';
import Time from '../../../../components/Time';
import Pagination from '../../../../components/Pagination.vue';
// import TripMap from "../../../../components/TripMap";
import ViewDuration from "../../../../components/Duration";
import Swal from "sweetalert2";
import Select2 from '../../../../components/Select2.vue';
export default {
  components: {ViewDuration, SubmitButton, Time, Pagination, Select2 },
  name: "admin.management.users.view",
  props: ['id'],
  data(){
    return {
      user: {},
      assignedRoles: [],
      assignedClients: [],
      types: [],
      loading: true,
      updating: false,
      timesheets: {
        start: '',
        end: '',
        entries: [],
        creating: false,
        edit: {

        },
        new: {

        }
      },
      roles: [],
      rolePagination: {},
      clientSearch: "",
      clients: [],
      clientPagination: {},
      edit: false,
      vehicle: {
        registration: "",
        vin: "",
        make: "",
        model: "",
        colour: "",
        type: ""
      },
      creating: false,
      currentVehicle: {
        registration: "",
        vin: "",
        make: "",
        model: "",
        colour: "",
        type: "",
        tracker_imei: ""
      },
      trips: {
        trips: [],
        start: null,
        end: null
      },
      currentTrip: {},
      currentMarker: {},
      currentExemption: {},
      loadingMileage: true,
      exemption: {
        start: null,
        formattedStart: null,
        end: null,
        formattedEnd: null,
        reason: null,
        vehicle_id: null,
        trip: null,
        error: null
      },
      multipleExemptions: {
        trips: null,
        error: null,
        reason: null
      },
      exemptions: [],
      paysheet: {
        start: null,
        end: null,
        timesheets: {

        },
        mileage: {

        }
      },
      staging: {
        devices: [],
        loading: true
      },
      training: {
        name: null,
        start: null,
      },
      trainingModules: [],
      currentDevice: null,
      availableModules: [],
      loadingPaysheet: false,
      annualLeaveFilters: {
        start: DateTime.local().startOf('year').toFormat('yyyy-MM-dd'),
        end: DateTime.local().endOf('year').toFormat('yyyy-MM-dd')
      },
      overnightsFilter: {
        month: null
      },
      overnights: [],
      totalOvernights: 0,
      annualLeave: {
        start: null,
        end: null,
        notes: null
      },
      leave: [],
      jobNumbers: ""
    }
  },
  mounted(){
    this.timesheets.start = DateTime.local().startOf('month').toFormat('yyyy-MM-dd');
    this.timesheets.end = DateTime.local().endOf('month').toFormat('yyyy-MM-dd');
    this.trips.start = DateTime.local().startOf('week').toFormat('yyyy-MM-dd');
    this.trips.end = DateTime.local().endOf('week').toFormat('yyyy-MM-dd');
    this.paysheet.start = DateTime.local().startOf('month').toFormat('yyyy-MM-dd');
    this.paysheet.end = DateTime.local().endOf('month').toFormat('yyyy-MM-dd');
    this.overnightsFilter.month = DateTime.local().startOf('month').toFormat('yyyy-MM');
    this.loadUser();
    this.loadTraining();
    this.loadTimesheets();
    // this.loadTrips();
    // this.loadExemptions();
    this.loadUserTypes();
    this.loadAnnualLeave();
    this.loadOvernights();
  },
  methods: {
    assignJobs(){
      let jobs = this.jobNumbers.split(',');
      if(jobs.length < 1){
        this.$error("Please enter job numbers to assign.");
        return;
      }

      axios.put(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/jobs`, {
        job_ids: jobs
      })
      .then(() => {
        this.$success("Assigned jobs to user.");
        this.jobNumbers = "";
      })
      .catch(error => {
        this.$error("Failed to assign jobs to user.", error);
      });
    },
    loadUser(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}`)
      .then(response => {
        response.data.user.training = [];
        this.user = response.data.user;
        this.user.vehicles.forEach((v) => {
          if(v.track_mileage === 1){
            v.track_mileage = true;
          }
        })
        this.loading = false;
        this.loadRoles(1);
        this.loadClients(1);
      })
      .catch(error => {
        this.$error("Failed loading users.", error);
      })
    },
    disableUser(){
      this.user.status = 'Disabled';
      this.updateUser();
    },
    enableUser(){
      this.user.status = 'Active';
      this.updateUser();
    },
    updateUser(){
      this.updating = true;
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}`, this.user)
      .then(() => {
        this.$success("Updated user.");
      })
      .catch(error => {
        this.$error("Failed to update user", error);
      })
      .finally(() => this.updating = false);
    },
    resetPassword(){

      Swal.fire({
        title: 'Are you sure?',
        text: "Are you sure you want to reset this users password?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reset it!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(`${process.env.VUE_APP_API_URL}/v1/users/reset`, {
            users: [this.user.id]
          })
              .then(() => {
                this.$success("Reset user.");
              })
              .catch(error => {
                this.$error("Failed to update user", error);
              })
        }
      });
    },
    loadTimesheets(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/timesheets?start=${this.timesheets.start}&end=${this.timesheets.end}`)
      .then(response => {
        console.log(response.data.timesheets);
        this.timesheets.entries = response.data.timesheets;
        this.timesheets.entries.forEach((entry) => {
          let start = DateTime.fromSeconds(entry.start, {zone: 'UTC'}).toLocal();
          let end = DateTime.fromSeconds(entry.end, {zone: 'UTC'}).toLocal().minus({minutes: entry.deductions || 0});

          const diff = end.diff(start, ["hours", "minutes", "seconds"]);
          let value = diff.toObject();

          entry.duration = `${value.hours.toString().padStart(2, '0')}:${value.minutes.toString().padStart(2, '0')}:${value.seconds.toString().padStart(2, '0')}`;
          entry.formattedStart = start.toFormat('yyyy-MM-dd HH:mm:ss').split(' ').join('T');
          entry.formattedEnd = end.toFormat('yyyy-MM-dd HH:mm:ss').split(' ').join('T');
          entry.formattedEndNoDeductions = DateTime.fromSeconds(entry.end, {zone: 'UTC'}).toLocal().toFormat('yyyy-MM-dd HH:mm:ss').split(' ').join('T');
          entry.formattedVerification = entry.verified == 1;

          // Mileage difference
          let previousEntry = this.timesheets.entries[this.timesheets.entries.indexOf(entry) - 1];
          if(previousEntry && entry.start_mileage && previousEntry.end_mileage){
            entry.diff_since_last = entry.start_mileage - previousEntry.end_mileage;
          }else{
            entry.diff_since_last = 'N/A';
          }

        })
      })
      .catch(error => {
        this.$error("Unable to load timehseets", error);
      });
    },
    updateTimesheet(){

      let start = parseInt(DateTime.fromISO(this.timesheets.edit.formattedStart).toUTC().toSeconds());
      let end = parseInt(DateTime.fromISO(this.timesheets.edit.formattedEndNoDeductions).toUTC().toSeconds());

      axios.patch(`${process.env.VUE_APP_API_URL}/v1/timesheets/${this.timesheets.edit.id}`, {
        start: start,
        end: end,
        start_mileage: parseInt(this.timesheets.edit.start_mileage || 0),
        end_mileage: parseInt(this.timesheets.edit.end_mileage || 0),
        driver_name: this.timesheets.edit.driver_name || null,
        verified: this.timesheets.edit.formattedVerification,
        deductions: parseInt(this.timesheets.edit.deductions || 0),
        mileage_deductions: parseFloat(this.timesheets.edit.mileage_deductions || 0)
      })
      .then(() => {
          this.$success("Updated timesheet");
          $('#closeEditDeductionsModal').click();
          this.loadTimesheets();
      })
      .catch(error => {
        this.$error("Failed to update timesheet", error);
      })
    },
    createTimesheet(){
      this.timesheets.creating = true;
      let start = parseInt(DateTime.fromISO(this.timesheets.new.start).toSeconds());
      let end = parseInt(DateTime.fromISO(this.timesheets.new.end).toSeconds());
      axios.put(`${process.env.VUE_APP_API_URL}/v1/users/${this.user.id}/timesheets`, {
        start: start,
        end: end,
        start_mileage: parseInt(this.timesheets.new.start_mileage || 0),
        end_mileage: parseInt(this.timesheets.new.end_mileage || 0),
        driver_name: this.timesheets.new.driver_name || null,
        verified: this.timesheets.new.formattedVerification,
        deductions: parseInt(this.timesheets.new.deductions || 0),
        mileage_deductions: parseFloat(this.timesheets.new.mileage_deductions || 0)
      })
          .then(() => {
            this.$success("Created timesheet");
            $('#closeCreateEntryModal').click();
            this.loadTimesheets();
          })
          .catch(error => {
            this.$error("Failed to create timesheet", error);
          })
      .finally(() => {
        this.timesheets.creating = false;
      })
    },
    deleteTimesheet(entry){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/timesheets/${entry.id}`)
      .then(response => {
          if(response.data){
            if(response.data.success){
              this.$success("Deleted timesheet entry!");
              this.loadTimesheets();
              return;
            }
          }
          this.$error("Failed to delete timesheet entry", new Error('Failed to delete timesheet entry'));
      })
      .catch(error => {
        this.$error("Failed to delete timesheet entry", error);
      });
    },
    loadClients(page){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client?page=${page}&search=${this.clientSearch}`)
      .then(response => {
        this.clients = response.data.clients;
        this.clients.forEach((client) => {
          let assignedClient = this.user.clients.filter((c) => {
            return c.id == client.id;
          });
          client.assigned = assignedClient.length > 0;
        });
        this.clientPagination = response.data.pagination;
      })
      .catch(error => {
        this.$error("Failed to load clients", error);
      })
    },
    loadRoles(page){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/roles?page=${page}`)
      .then(response => {
        this.roles = response.data.roles;
        this.roles.forEach((role) => {
          let assignedRole = this.user.roles.filter((r) => {
            return r.id == role.id;
          });
          role.assigned = assignedRole.length > 0;
        });
        this.rolePagination = response.data.pagination;
      })
      .catch(error => {
        this.$error("Failed to load roles", error);
      })
    },
    assignClient(client){
      axios.put(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/clients`, {
        client_id: client.id
      })
      .then(() => {
        this.$success("Added client to user");
        this.user.clients.push(client);
        client.assigned = true;
      })
      .catch(error => {
        this.$error("Failed to add client to user", error);
      })
    },
    assignRole(role){
      axios.put(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/roles`, {
        role_id: role.id
      })
      .then(() => {
        this.$success("Added role to user");
        this.user.roles.push(role);
        role.assigned = true;
      })
      .catch(error => {
        this.$error("Failed to add role to user", error);
      })
    },
    removeClient(client){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/clients`, {
        data: {
          client_id: client.id
        }
      })
          .then(() => {
            this.$success("Removed client from user");
            this.user.clients = this.user.clients.filter((c) => {
              return c.id !== client.id;
            });
            client.assigned = false;
          })
          .catch(error => {
            this.$error("Failed to remove client from user", error);
          })
    },
    removeRole(role){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/roles`, {
        data: {
          role_id: role.id
        }
      })
          .then(() => {
            this.$success("Removed role from user");
            this.user.roles = this.user.roles.filter((r) => {
              return r.id !== role.id;
            });
            role.assigned = false;
          })
          .catch(error => {
            this.$error("Failed to remove role from user", error);
          })
    },
    createVehicle(){
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/users/${this.user.id}/vehicles`, this.vehicle)
        .then(() => {
          $('#closeCreateVehicleModal').click();
          this.$success("Created new vehicle");
          this.creating = false;
          this.vehicle = {
            registration: "",
            make: "",
            model: "",
            colour: "",
            type: ""
          }
          this.loadUser();
        })
        .catch(error => {
          this.$error("Failed to create new vehicle", error);
          this.creating = false;
        })
    },
    updateVehicle(){
      this.creating = true;
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/vehicles/${this.currentVehicle.id}`, {
        make: this.currentVehicle.make,
        model: this.currentVehicle.model,
        colour: this.currentVehicle.colour,
        vin: this.currentVehicle.vin,
        tracker_imei: this.currentVehicle.tracker_imei,
        type: this.currentVehicle.type,
        track_mileage: this.currentVehicle.track_mileage
      })
      .then(() => {
        this.$success("Updated vehicle");
        this.loadUser();
        $('#closeUpdateVehicleModal').click();
      })
      .catch(error => {
        this.$error("Failed to update vehicle!", error);
      })
      .finally(() => {
        this.creating = false;
      })
    },
    loadTrips(){
      this.loadingMileage = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/mileage?start=${this.trips.start}&end=${this.trips.end}`)
      .then(response => {
        this.trips.trips = response.data.trips;
        this.trips.trips.forEach((trip) => {
          trip.formattedDuration = Duration.fromObject({seconds: trip.duration}).toFormat('hh:mm:ss');
          trip.checked = false;
        })
      })
      .catch(error => {
        this.$error("Failed to load mileage", error);
      })
      .finally(() => {
        this.loadingMileage = false;
      })
    },
    viewTrip(trip){
      setTimeout(() => {
        this.currentTrip = trip;
      }, 1000);
    },
    showMarker(marker){
      this.currentMarker = marker;
    },
    createExemption(){
      this.exemption.error = null;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/exemptions`, {
        start: this.exemption.start,
        end: this.exemption.end,
        reason: this.exemption.reason,
        vehicle_id: this.exemption.vehicle_id
      })
      .then(response => {
        if(response.data.success){
          this.exemption.trip.exemptions.push({
            id: response.data.id,
            start: this.exemption.start,
            end: this.exemption.end,
            approved: false,
            reason: this.exemption.reason,
            user_id: this.id,
            vehicle_id: this.exemption.vehicle_id
          });
          this.exemption = {
            start: null,
            formattedStart: null,
            end: null,
            formattedEnd: null,
            reason: null,
            vehicle_id: null,
            trip: null
          }
          $('#closeCreateExemptionModal').click();
        }
      })
      .catch(error => {
        this.$error("Failed to add exemption", error);
        if(error.response){
          if(error.response.data){
            this.exemption.error = error.response.data.message;
            return;
          }
        }
        this.exemption.error = "Failed to create exemption, please try again!";
      })
    },
    showCreateExemption(trip){
      this.exemption.start = trip.start;
      this.exemption.end = trip.end;
      this.exemption.formattedStart = DateTime.fromSeconds(trip.start).toFormat('yyyy-MM-dd HH:mm:ss').split(' ').join('T');
      this.exemption.formattedEnd = DateTime.fromSeconds(trip.end).toFormat('yyyy-MM-dd HH:mm:ss').split(' ').join('T');
      this.exemption.vehicle_id = trip.vehicle.id;
      this.exemption.trip = trip;
      $('#createExemptionModal').modal('toggle');
    },
    showCreateMultipleExemption(){
      this.multipleExemptions.trips = this.selectedTrips;
      $('#createMultipleExemptionModal').modal('toggle');
    },
    createMultipleExemptions(){
      this.multipleExemptions.error = null;
      let payload = [];
      this.multipleExemptions.trips.forEach((trip) => {
        payload.push({
          start: trip.start,
          end: trip.end,
          reason: this.multipleExemptions.reason,
          vehicle_id: trip.vehicle.id
        })
      })
      axios.post(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/exemptions`, {
        trips: payload
      })
      .then(response => {
        if(response.data.success){
          // response.data.exemptions.forEach((e) => {
            // this.exemption.trip.exemptions.push({
            //   id: e.id,
            //   start: this.exemption.start,
            //   end: this.exemption.end,
            //   approved: false,
            //   reason: this.exemption.reason,
            //   user_id: this.id,
            //   vehicle_id: this.exemption.vehicle_id
            // });

          // })

          response.data.passed.forEach((e) => {

            let assosciatedTrips = this.multipleExemptions.trips.filter((trip) => {
              return trip.start >= e.start && trip.end <= e.end;
            });

            assosciatedTrips.forEach((t) => {
              t.exemptions.push({
                id: e.exemption_id,
                start: e.start,
                end: e.end,
                approved: false,
                reason: e.reason,
                user_id: this.id,
                vehicle_id: e.vehicle_id
              });
            });

          });

          this.multipleExemptions = {
            trips: null,
            reason: null,
            error: null
          }
          $('#closeCreateMultipleExemptionModal').click();
          
        }
      })
      .catch(error => {
        this.$error("Failed to add exemptions", error);
        if(error.response){
          if(error.response.data){
            this.multipleExemptions.error = error.response.data.message;
            return;
          }
        }
        this.multipleExemptions.error = "Failed to create exemptions, please try again!";
      })
    },
    approveExemption(currentExemption){
      if(!this.$hasRole('Admin')){
        this.$error("You do not have permission to complete that action!");
        return;
      }
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/exemptions/${currentExemption.id}`, {
        approved: 1
      })
      .then(response => {
        if(response.data){
          this.$success("Approved Exemption!");
          if(currentExemption === this.currentExemption){
            currentExemption.fname = this.loggedInUser.fname;
            currentExemption.lname = this.loggedInUser.lname;
            currentExemption.reviewed_at = DateTime.local().toSeconds();
            currentExemption.approved = 1;
          }else{
            this.loadTrips();
          }
          this.exemptions = this.exemptions.filter((e) => {
            return e.id !== currentExemption.id;
          });
        }
      })
      .catch(error => {
        this.$error("Failed to approve exemption", error);
      })
    },
    denyExemption(currentExemption){
      if(!this.$hasRole('Admin')){
        this.$error("You do not have permission to complete that action!");
        return;
      }
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/exemptions/${this.currentExemption.id}`, {
        approved: 0
      })
          .then(response => {
            if(response.data){
              this.$success("Denied Exemption!");
              if(currentExemption === this.currentExemption) {
                currentExemption.fname = this.loggedInUser.fname;
                currentExemption.lname = this.loggedInUser.lname;
                currentExemption.reviewed_at = DateTime.local().toSeconds();
                currentExemption.approved = 0;
              }else{
                this.loadTrips();
              }
              this.exemptions = this.exemptions.filter((e) => {
                return e.id !== currentExemption.id;
              });
            }
          })
          .catch(error => {
            this.$error("Failed to deny exemption", error);
          })
    },
    loadExemptions(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/exemptions`)
      .then(response => {
        this.exemptions = response.data.exemptions;
      })
      .catch(error => {
        this.$error("Failed to load exemptions", error);
      });
    },
    loadPaysheet(){
      this.loadingPaysheet = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/paysheet?start=${this.paysheet.start}&end=${this.paysheet.end}`)
      .then(response => {
        this.paysheet.timesheets = response.data.timesheets;
        this.paysheet.mileage = response.data.mileage;
      })
      .catch(error => {
        this.$error("Failed to load Paysheet", error);
      })
      .finally(() => {
        this.loadingPaysheet = false;
      })
    },
    loadStaging(){
      this.staging.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/staging`)
      .then(response => {
        this.staging.devices = response.data.staging;
      })
      .catch(error => {
        this.$error("Failed to load Staging devices", error);
      })
      .finally(() => {
        this.staging.loading = false;
      })
    },
    showStaging(){
      this.loadStaging();
      $('#viewStagingModal').modal('toggle');
    },
    assignDevice(device){
      axios.put(`${process.env.VUE_APP_API_URL}/v1/staging/${device.staging_key}`, {
        name: device.name || "",
        user_id: this.id
      })
      .then(response => {
        if(response.data.success){
          this.$success("Assigned device");
          $('#viewStagingModal').modal('toggle');
          this.loadUser();
          this.staging.devices = this.staging.devices.filter((d) => {
            return d.staging_key !== device.staging_key;
          });
        }
      })
      .catch(error => {
        this.$error("Failed to assign device", error);
      })
    },
    deleteDevice(device){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/staging/${device.staging_key}`)
          .then(response => {
            if(response.data.success){
              this.staging.devices = this.staging.devices.filter((d) => {
                return d.staging_key !== device.staging_key;
              });
            }
          })
          .catch(error => {
            this.$error("Failed to delete device", error);
          })
    },
    removeDevice(device){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/devices/${device.id}`)
          .then(response => {
            if(response.data.success){
              this.loadUser();
            }
          })
          .catch(error => {
            this.$error("Failed to remove device", error);
          })
    },
    manageDevice(device){
      this.currentDevice = device;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/userdevices/${device.id}/modules`)
      .then(response => {
        this.availableModules = response.data.available;
        Object.keys(response.data.defined).forEach((m) => {
          if(m !== 'user_devices_id'){
            response.data.defined[m] = response.data.defined[m] === 1
          }
        })
        this.currentDevice.modules = response.data.defined || {};
        $('#viewManageDeviceModal').modal('toggle');
      })
      .catch(error => {
        this.$error("Failed to load modules", error);
      })
    },
    updateDeviceModules(){
      this.creating = true;
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/userdevices/${this.currentDevice.id}/modules`, {
        modules: JSON.stringify(this.currentDevice.modules)
      })
      .then(response => {

        if(response.data.success){
          this.$success("Updated modules");
          $('#closeManageDeviceModal').click();
        }

      })
      .catch(error => {
        this.$error("Failed to update device modules", error);
      })
      .finally(() => {
        this.creating = false;
      })
    },
    loadUserTypes(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/types/list`)
          .then(response => {
            this.types = response.data.types;
            this.types.forEach((type) => {
              type.text = type.name
            });
          })
          .catch(error => {
            this.$error("Failed to load User Types", error);
          })
    },
    loadTraining(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/usertraining/${this.id}/training`)
      .then(response => {
        this.trainingModules = response.data.training;
        this.trainingModules.forEach((t) => {
          t.formattedStart = DateTime.fromISO(t.start).toFormat('dd/MM/yyyy');
          t.formattedExpiry = DateTime.fromISO(t.expiry).toFormat('dd/MM/yyyy');
        });
      })
      .catch(error => {
        this.$error("Failed to load training", error);
      })
    },
    addTraining(){
      axios.post(`${process.env.VUE_APP_API_URL}/v1/usertraining/${this.id}/training`, {
        name: this.training.name,
        start: this.training.start,
        expiry: this.training.expiry
      })
      .then(response => {
        if(response.data.success){
          this.$success("Added training");
          this.training = {
            name: null,
            start: null,
            expiry: null
          }
          $('#addTrainingModal').modal('toggle');
          this.loadTraining();
        }
      })
      .catch(error => {
        this.$error("Failed to add training", error);
      })
    },
    removeTraining(training){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/usertraining/${this.id}/training`, {
        data: {
          name: training.name
        }
      })
      .then(response => {
        if(response.data.success){
          this.$success("Removed training");
          this.loadTraining();
        }
      })
      .catch(error => {
        this.$error("Failed to remove training", error);
      })
    },
    showTraining(){
      $('#addTrainingModal').modal('toggle');
    },
    editTraining(training){
      this.training = training;
      $('#editTrainingModal').modal('toggle');
    },
    updateTraining(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/usertraining/${this.id}/training`, {
        name: this.training.name,
        start: this.training.start,
        expiry: this.training.expiry
      })
      .then(response => {
        if(response.data.success){
          this.$success("Updated training");
          this.training = {
            name: null,
            start: null,
            expiry: null
          }
          $('#editTrainingModal').modal('toggle');
          this.loadTraining();
        }
      })
      .catch(error => {
        this.$error("Failed to update training", error);
      })
    },
    loadOvernights(){
      this.totalOvernights = 0;
      let month = new Date(this.overnightsFilter.month);
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/overnights`, {
        params: {
          month: month.getMonth()
        }
      })
      .then(response => {
        this.overnights = response.data.overnights;
        this.overnights.forEach(entry => {
          this.totalOvernights = this.totalOvernights + entry.overnights;
        });
      })
      .catch(error => {
        this.$error("Failed to load overnight", error);
      })
    },
    deleteOvernight(overnight){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/users//${this.id}/overnights`, {
        data: {
          overnights: overnight.overnights,
          week: overnight.week,
          month: overnight.month
        }
      })
      .then(response => {
        if(response.data.success){
          this.$success("Removed overnight");
          this.loadOvernights();
        }
      })
      .catch(error => {
        this.$error("Failed to remove overnight", error);
      })
    },
    loadAnnualLeave(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/userannualleave/${this.id}`, {
        params: {
          start: this.annualLeaveFilters.start,
          end: this.annualLeaveFilters.end
        }
      })
      .then(response => {
        response.data.annualLeave.forEach((al) => {
          al.start_date = DateTime.fromISO(al.start_date).toFormat('dd/MM/yyyy');
          al.end_date = DateTime.fromISO(al.end_date).toFormat('dd/MM/yyyy');
        })
        this.leave = response.data.annualLeave;
      })
      .catch(error => {
        this.$error("Failed to load annual leave", error);
      })
    },
    postAnnualLeave(){
      axios.post(`${process.env.VUE_APP_API_URL}/v1/userannualleave/${this.id}`, {
        start: this.annualLeave.start,
        end: this.annualLeave.end,
        notes: this.annualLeave.notes
      })
      .then(response => {
        if(response.data.success){
          this.$success("Added annual leave");
          this.annualLeave = {
            start: null,
            end: null,
            notes: null
          }
          $('#addAnnualLeaveModal').modal('toggle');
          this.loadAnnualLeave();
        }
      })
      .catch(error => {
        this.$error("Failed to add annual leave", error);
      })
    },
    deleteAnnualLeave(leave){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/userannualleave/${this.id}`, {
        data: {
          id: leave.id
        }
      })
      .then(response => {
        if(response.data.success){
          this.$success("Removed annual leave");
          this.loadAnnualLeave();
        }
      })
      .catch(error => {
        this.$error("Failed to remove annual leave", error);
      })
    },
    showAnnualLeave(){
      $('#addAnnualLeaveModal').modal('toggle');
    }
  },
  computed: {
    selectedTrips: function(){
      return this.trips.trips.filter((t) => {
        return t.checked === true;
      })
    }
  }
}
</script>